video {
    width: auto;
    min-width: 100%;
    max-width: none;
    height: auto;
    min-height: 100%;
    z-index: -11;
    background-size: cover;
    /*background-size: auto 100%;*/

    opacity: 0.3;

        position: absolute;
    /*top: 50%;*/
    /*left: 50%;*/
    /*-webkit-transform: translate(-50%,-50%);*/

    top: -27px;
    left: 50%;
    -webkit-transform: translate(-50%);

    object-fit: contain;
}
